const darkViolet = '#1a0026';
const lilac = '#ebd0f7';
const violet = '#53388a';
const violetGradation1 = '#9888B9';
const violetGradation2 = '#E0DCEA';
const pink1 = '#E589ED';

const Colors = {
  pink: '#f542ec',
  blue: '#42c2f5',
  white: 'white',
  black: '#1a1a1a',
  headerBackground: darkViolet,
  pageBackground: '#fff',
  categoryBackground: '#ccc',
  textColor: darkViolet,//'#232129',
  articleBackground: '#fff',//'#1a0026',
  metaCategoryColor: '#777',
  articleHeaderSeparatorColor: darkViolet,
  violet,
  headerTextColor: '#fff',
  violetGradation1,
  violetGradation2,
  contrastColor: pink1,
  multiSelectColors: [
    'rgb(82, 67, 170)',
    '#E88BEF',
    'rgb(255, 196, 0)',
    'rgb(0, 184, 217)',
    'rgb(0, 135, 90)',
    'rgb(102, 102, 102)',
    'rgb(255, 139, 0)',
    'rgb(255, 86, 48)',
  ],
};

export default Colors;