import * as React from "react";
import Colors from "../colors";
import {css, Global, jsx} from '@emotion/react';
import Header from "./Header";
import Jumbotron from "./Jumbotron";

// styles
const pageStyles = {
  color: Colors.textColor,
  backgroundColor: Colors.pageBackground,
  fontFamily: "Roboto, sans-serif, serif",
  display: 'flex',
  flexDirection: 'column',
}

const LayoutComponent = ({ children }) => {
  return (
    <main style={pageStyles}>
      <Global
        styles={css`
          html, body {
            background-color: ${Colors.pageBackground}
          }
        `}
      />

      <a id="top"/>

      <Header/>
      <Jumbotron/>

      <div css={css`
        height: 24px;
        background-color: ${Colors.violetGradation1};
      `}/>
      <div css={css`
        height: 24px;
        background-color: ${Colors.violetGradation2};
      `}/>

      <div css={css`
        box-sizing: border-box;
        width: 100%;
        min-width: 0;
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
      `}>
        {children}
      </div>

      <div css={css`
        height: 24px;
        background-color: ${Colors.violetGradation2};
        margin-top: 64px;
      `}/>
      <div css={css`
        height: 24px;
        background-color: ${Colors.violetGradation1};
      `}/>

      <div
        css={css`
          height: 48px;
          background: ${Colors.violet};
        `}
      >

      </div>
    </main>
  );
};

export default LayoutComponent;
