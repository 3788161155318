import * as React from "react";
import Colors from "../colors";
import { css, jsx } from '@emotion/react';

const middleBorderSize = 4;

let listItemStyle = css`
  padding-left: 1rem;
`;

const navLinkStyle = css`
    padding: 1rem 0;
    color: ${Colors.headerTextColor};
    text-decoration: none;
    cursor: pointer;
`;

const HeaderComponent = () => {
    return (
      <header css={css`
            display: flex;
            background: ${Colors.violet};
            position: fixed;
            width: 100%;
            top: 0;
            z-index: 10;
      `}>
          <div css={css`
                box-sizing: border-box;
                width: 100%;
                min-width: 0;
                max-width: 1024px;
                margin-left: auto;
                margin-right: auto;
                padding: 0 32px;
          `}>
              <nav css={css`
                 background: ${Colors.violet};
                 display: flex;
                 justify-content: space-between;
                 align-items: center;
                 box-pack: justify;
              `}>
                  <a href="/#top" css={css`
                    display: inline-block;
                    font-family: Pacifico, Roboto, sans-serif;
                    padding: 1rem 0;
                    color: ${Colors.headerTextColor};
                    text-decoration: none;
                    cursor: pointer;
                    font-size: 32px;
                  `}>dizzy.codes</a>

                  <ul css={css`
                    display: flex;
                    list-style: none;
                    margin:0;
                  `}>
                      <li css={listItemStyle}>
                          <a href="#experience" css={navLinkStyle}>Experience</a>
                      </li>
                      <li css={listItemStyle}>
                          <a href="#projects" css={navLinkStyle}>Projects</a>
                      </li>
                  </ul>
              </nav>
          </div>
      </header>
  );
}

export default HeaderComponent;
