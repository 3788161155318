import * as React from "react";
import Colors from "../colors";
import { css, jsx } from '@emotion/react';

const dotCss = css`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${Colors.violetGradation1};
  display: inline-block;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

const subtitleStyles = css`
  font-style: italic;
  font-size: 17px;
  text-align: center;
  display: block;
`;

const dotContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const linkStyles = css`
  color: white;
  font-size: .9em;
  &:hover {
    color: white;
  }
`;

const Dots = () => {
  return (
    <p css={dotContainerStyles}>
      <span css={dotCss}/>
      <span css={dotCss}/>
      <span css={dotCss}/>
    </p>
  );
}

const JumbotronComponent = () => {
  return (
    <div css={css`
      background-color: ${Colors.violet};
      min-height: 440px;
      position: relative;
    `}>
      <div css={css`
        box-sizing: border-box;
        width: 100%;
        min-width: 0;
        max-width: 1024px;
        position: absolute;
        bottom: 32px;
        height: calc(100% - ${88+32}px);
        left: 50%;
        transform: translateX(-50%);
        color: ${Colors.headerTextColor};
      `}>
        <div css={css`
        box-sizing: border-box;
          padding: 32px 32px;
          height: calc(100%); 
        `}>
          <h1 css={css`
            font-family: Pacifico, Roboto, sans-serif;
            font-size: 60px;
            margin-top: 0;
            text-align: center;
            font-weight: 200;
            margin-bottom: 0;
          `}>
            Dizzy Faefyre
          </h1>
          <p css={subtitleStyles}>
            Full Stack Software Engineer • DevOps Engineer • Software Generalist
          </p>
          <Dots/>
          <p css={subtitleStyles}>
            node.js • PHP • React • Vue • Angular • MySQL • AWS • Flutter
          </p>
          <Dots/>
          <p css={subtitleStyles}>
            <a css={linkStyles} href="https://codeberg.org/elliprime" rel="noreferrer noopener" target="_blank">https://codeberg.com/elliprime</a> • <a css={linkStyles} href="https://github.com/elliprime" rel="noreferrer noopener" target="_blank">https://github.com/elliprime</a> • <a css={linkStyles} href="https://github.com/diosmosis" rel="noreferrer noopener" target="_blank">https://github.com/diosmosis</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default JumbotronComponent;
